<template>
  <LayerPopup :popOpen="params.open" @popClose="onClose" title="입주인원" class="person_room_pop slide">
    <div class="wrap_select">
      <ul class="list_select" role="listbox">
        <li class="item_select" role="presentation" v-for="(info, idx) in visitCount"
            :key="idx">
          <a href="#" role="option" :aria-selected="idx + 1 === Number(code_count) ? 'true' : 'false'"
              @click.prevent="setVisitCount(info,idx+1)">{{ info }}</a>
        </li>
      </ul>
      <div class="btn_group">
        <a href="#" role="button" class="btn btn-basic" @click.prevent="onClose(false)">취소</a>
      </div>
    </div>
  </LayerPopup>
</template>

<script>
import LayerPopup from '@/components/base/LayerPopup.vue';

export default {
  id: 'countPopup',
  components: {
    LayerPopup,
  },
  props: {
    params: {
      type: Object,
      default: () => ({
        open: false,
      }),
    },
    code_count: String,
  },
  data() {
    return {
      visitCount: [
        this.$t('content.center.GangnamReservation.txt06_3'),
        this.$t('content.center.GangnamReservation.txt06_4'),
        this.$t('content.center.GangnamReservation.txt06_5'),
        this.$t('content.center.GangnamReservation.txt06_6'),
        this.$t('content.center.GangnamReservation.txt06_7'),
        this.$t('content.center.GangnamReservation.txt06_8'),
      ],
    };
  },
  methods: {
    onClose(state) {
      this.params.open = state;
    },
    setVisitCount(count, code) {
      this.$emit('setVisitCount', count, String(code));
      this.onClose(false);
    },
  },
};
</script>
