<template>
  <LayerPopup :popOpen="params.open"
              @popClose="onClose"
              :fixedBtn="true"
              :fullpopup="true"
              title="개인정보 동의"
              class="layer">
    <div class="terms-container">
      <!-- <header class="header_visit">
        <h1 class="tit">정보수집 및 이용동의</h1>
        <a href="#" role="button" class="btn_close" @click.prevent="onCancel">
          <span class="blind">닫기</span>
        </a>
      </header> -->
      <div class="view-box">
        <p class="desc">
          한화생명보험주식회사(이하 ‘회사’라 함) 드림플러스 이노베이션 허브 홈페이지(이하 “홈페이지”라 함)는 이용자의
          개인정보를 보호하기 위하여 최선을 다하고 있으며, 이를 위해 ‘정보통신망 이용촉진 및 정보보호 등에 관한 법률’,
          ‘개인정보보호법’ 등 관련 법령에 따라 아래와 같이 개인정보처리방침을 수립하여 시행하고 있습니다.
          개인정보처리방침은 개인정보가 어떻게 보호조치가 되고 있는지 알려드리는 기본지침으로서 관련 법령 및 회사
          내부운영방침의 변경에 따라 수시로 변경될 수 있습니다. 만약 개인정보처리방침을 변경하는 경우에는
          홈페이지를 통해 공지하고 있습니다.
        </p>
        <dl class="terms">
          <dt>1. 수집하는 개인정보 항목</dt>
          <dd>성명, 전화번호, 이메일</dd>
          <dt>2. 개인 정보의 수집/이용 목적</dt>
          <dd>드림플러스 강남센터 방문자 예약 및 회신 서비스</dd>
          <dt>3. 개인 정보의 보유 및 이용 기간</dt>
          <dd>귀하의 개인 정보의 보유 및 이용기간은 등록한 시점을 기준으로 1년간 보관되며,
            귀하는 동의를 거절할 수 있는 권리를 보유하며 동의를 거절하는 경우 당사는 상기 “수집/이용 목적”란에
            명시된 서비스를 귀하에게 제공할 수 없습니다.</dd>
        </dl>
      </div>
    </div>
    <div class="btn_group">
        <button type="button" class="btn btn-basic"
                @click.prevent="onClose">
          확인</button>
      </div>
  </LayerPopup>
</template>

<script>
import LayerPopup from '@/components/base/LayerPopup.vue';

export default {
  id: 'countPopup',
  components: {
    LayerPopup,
  },
  props: {
    params: {
      type: Object,
      default: () => ({
        open: false,
      }),
    },
  },
  methods: {
    onCancel() {
      this.$emit('setAgreed', false);
      this.params.open = false;
    },
    onClose() {
      this.$emit('setAgreed', true);
      this.params.open = false;
    },
  },
};
</script>
<style scoped>
  .contents_agree {
    padding-bottom: 130px;
  }
</style>
