<template>
  <LayerPopup :popOpen="params.open" :fixedBtn="true" @popClose="onClose" class="timepicker_pop slide">
    <div class="pop_list">
      <span v-for="(info, idx) in visitTimes"
            :key="idx"
            :class="[info.time === visit_time ? 'on' :
            [isVisitTime[idx] ? '':'disabled']]"
            @click.prevent="setVisitTime(info.time,isVisitTime[idx])"
      >{{ info.time }}</span>
    </div>
    <div class="btn_group">
      <a href="#" class="btn btn_line" @click.prevent="onClose(false)">취소</a>
    </div>
  </LayerPopup>
</template>

<script>
import LayerPopup from '@/components/base/LayerPopup.vue';

export default {
  id: 'timePopup',
  components: {
    LayerPopup,
  },
  props: {
    params: {
      type: Object,
      default: () => ({
        open: false,
      }),
    },
    isVisitTime: {
      type: Array,
      default: () => {},
    },
    visit_time: String,
  },
  data() {
    return {
      visitTimes: [
        { time: '14:00' },
        { time: '15:00' },
      ],
    };
  },
  methods: {
    onClose(state) {
      this.params.open = state;
    },
    setVisitTime(time, isTime) {
      if (!isTime) {
        return;
      }
      this.$emit('setVisitTime', time);
      this.onClose(false);
    },
  },
};
</script>
