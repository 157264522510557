<template>
  <div>
    <div class="wrap_visit">
      <div class="blind"><a href="#container">본문 바로가기</a></div>
      <header class="header_visit">
        <a href="#" role="button" class="btn_prev" @click="goBack" v-show="resultType === 1">
          <span class="blind">이전</span>
        </a>
        <h1 class="tit">{{$t('content.center.GangnamReservation.txt01')}}</h1>
      </header>
    </div>
    <div class="container_visit">
      <!-- Tab Section -->
      <section class="tab-section contents_visit">
        <!-- Tab Contents -->
        <div class="tab-contents-wrap">
          <template v-if="resultType === 1">
            <!-- Form -->
            <div class="form-container">
              <ValidationObserver ref="form">
              <form>
                <fieldset>
                  <legend>{{$t('content.center.GangnamReservation.txt02_1')}}강남센터 방문 예약 입력</legend>
                  <!--<div class="align-right"><span class="required-mark">필수항목</span>는 필수입력 항목입니다.</div>-->
                  <ul>
                    <li class="form-row">
                      <BaseLabelElement
                        :label="$t('content.center.GangnamReservation.txt03')"
                        for="visitDate"
                        required
                      >
                      </BaseLabelElement>
                      <ValidationProvider
                        :rules="{ required: true }"
                        class="input-container input-md input-calendar"
                        :name="$t('content.center.GangnamReservation.txt03_1')"
                        v-slot="{ errors }"
                      >
                        <div :class="{'error':errors[0]}">
                          <input type="text" readonly="readonly"
                                 class="calendar hasDatepicker input-border"
                                 v-model="visitDate"
                                 @click="datePopupOpen"
                                 :class="{'error':errors[0]}"
                          >
                        </div>
                        <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
                      </ValidationProvider>
                      <div>
                        <ValidationProvider
                          :rules="{ required: true }"
                          class="input-container input-md input-select"
                          :name="$t('content.center.GangnamReservation.txt03_2')"
                          v-slot="{ errors }"
                        >
                          <div :class="{'error':errors[0]}" >
                            <input type="text" readonly="readonly"
                                   v-model="visit_time"
                                   @click="timePopupOpen"
                                   :class="{'error':errors[0]}"
                                   class="cursor input-border"
                                   :placeholder="$t('content.center.GangnamReservation.txt03_4')"
                            >
                          </div>
                          <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
                        </ValidationProvider>
                      </div>
                    </li>
                    <li class="form-row">
                      <ValidationProvider
                        :rules="{ required: true }"
                        :name="$t('content.center.GangnamReservation.txt04')"
                        v-slot="{ errors }"
                      >
                        <BaseInput
                          ref="visitor_name"
                          v-model="visitor_name"
                          :class="{'error':errors[0]}"
                          :label="$t('content.center.GangnamReservation.txt04')"
                          :placeholder="$t('content.center.GangnamReservation.txt04_1')"
                          required
                        />
                        <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
                      </ValidationProvider>
                    </li>
                    <li class="form-row">
                      <ValidationProvider
                        :rules="{ required: true }"
                        :name="$t('content.center.GangnamReservation.txt05')"
                        v-slot="{ errors }"
                      >
                        <BaseInput
                          v-model="visitor_company"
                          :class="{'error':errors[0]}"
                          :label="$t('content.center.GangnamReservation.txt05')"
                          :placeholder="$t('content.center.GangnamReservation.txt05_1')"
                          required
                        />
                        <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
                      </ValidationProvider>
                    </li>
                    <li class="form-row">
                      <ValidationProvider
                        :rules="{ required: true }"
                        :name="$t('content.center.GangnamReservation.txt06')"
                        v-slot="{ errors }"
                      >
                        <BaseLabelElement
                          :label="$t('content.center.GangnamReservation.txt06')"
                          for="codeVisitorCount"
                          required />
                        <div :class="{'error':errors[0]}" class="input-container input-select">
                          <input type="text" readonly="readonly"
                                 v-model="code_visitor_count"
                                 id="codeVisitorCount"
                                 @click="visitCountPopupOpen"
                                 :class="{'error':errors[0]}"
                                 class="input-border cursor"
                                 :placeholder="$t('content.center.GangnamReservation.txt06_1')"
                          >
                        </div>
                        <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
                      </ValidationProvider>
                    </li>
                    <li class="form-row">
                      <ValidationProvider
                        :rules="{ required: true, phone: true  }"
                        :name="$t('content.center.GangnamReservation.txt07')"
                        v-slot="{ errors }"
                      >
                        <TelInput
                          v-model="visitor_phone"
                          :label="$t('content.center.GangnamReservation.txt07')"
                          :placeholder="$t('content.center.GangnamReservation.txt07_1')"
                          :class="{'error':errors[0]}"
                          required
                          maxlength="13"
                        />
                        <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
                      </ValidationProvider>
                    </li>
                    <li class="form-row">
                      <ValidationProvider
                        :rules="{ required: true, email: true }"
                        :name="$t('content.center.GangnamReservation.txt08')"
                        v-slot="{ errors }"
                      >
                        <BaseInput
                          v-model="visitor_email"
                          :class="{'error':errors[0]}"
                          :label="$t('content.center.GangnamReservation.txt08')"
                          :placeholder="$t('content.center.GangnamReservation.txt08_1')"
                          required
                        />
                        <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
                      </ValidationProvider>
                    </li>
                  </ul>
                  <div class="wrap_check">
                    <input type="checkbox" class="blind" id="chk"
                           v-model="agreed"
                           @click="AgreementPopupOpen"
                    >
                    <label for="chk" class="label_check">
                      {{$t('content.center.GangnamReservation.txt09')}}
                    </label>
                  </div>
                </fieldset>
              </form>
              </ValidationObserver>
            </div>
            <!-- // Form -->
            <!-- 버튼 -->
            <div class="btn_bottom_wrap fixed">
              <button
                class="btn-basic"
                @click="reserve"
                :disabled="!agreed"
              >{{$t('content.center.GangnamReservation.txt13')}}
              </button>
            </div>
            <!-- // 버튼 -->
          </template>

          <template v-else-if="resultType === 2">
            <!-- 방문예약접수완료 -->
              <div class="reservation-result-wrap">
                <div class="reservation-result">
                  <h2 class="tit type_finish">{{$t('content.center.GangnamReservation.txt19')}}</h2>
                  <p class="desc" v-html="$t('content.center.GangnamReservation.txt14')"></p>
                </div>
              </div>
              <div class="btn_bottom_wrap">
                <button type="button" class="btn-basic" @click="goToReservation">
                  {{$t('content.common.button.btn04')}}</button>
              </div>
            <!-- //방문예약접수완료 -->
          </template>

          <template v-else>
            <!-- 방문예약불가 -->
            <div class="reservation-result-wrap">
              <div class="reservation-result">
                <h2 class="tit no-list"></h2>
                <p class="desc" v-html="$t('content.center.GangnamReservation.txt15')"></p>
              </div>
            </div>
            <div class="btn_bottom_wrap">
              <button type="button" class="btn-basic" @click="goToRefresh">
                {{$t('content.common.button.btn04')}}</button>
            </div>
            <!-- //방문예약불가 -->
          </template>
        </div>
        <!-- // Tab Contents -->
      </section>
      <!-- // Tab Section-->
    </div>
    <DatepickerPopup
      ref="datepickerPopup"
      :params="datepickerParams"
      :disabledDates="disabledDates"
      @setVisitTimeValue="setVisitTimeValue"
    />
    <TimePopup
      :params="timeParams"
      :isVisitTime="isVisitTime"
      :visit_time="visit_time"
      @setVisitTime="setVisitTime"
    />
    <VisitCountPopup
      :params="visitCountParams"
      :code_count="code_count"
      @setVisitCount="setVisitCount"
    />
    <AgreementPopup
    :params="agreementParams"
    @setAgreed="setAgreed"
    />
  </div>
</template>

<script>
// import '@/assets/css/reset.css';
// import '@/assets/css/common.css';
// import '@/assets/css/contents.css';
// import '@/assets/css/main_new.css';
// import '@/assets/css/reservation.css';
import '@/assets/css/reservationmobile.css';
import { CenterGangnamAPIs } from '@/common/portalApi';
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import BaseLabelElement from '@/components/base/BaseLabelElement.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import TelInput from '@/components/base/TelInput.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import DatepickerPopup from '@/components/calendar/DatepickerPopup.vue';
import TimePopup from '@/components/popup/TimePopup.vue';
import VisitCountPopup from '@/components/popup/VisitCountPopup.vue';
import AgreementPopup from '@/components/popup/AgreementPopup.vue';
import GangnamReservation from './GangnamReservation.vue';

export default {
  name: 'GangnamReservationMobile',
  extends: GangnamReservation,
  mixins: [mixinHelperUtils],
  components: {
    BaseLabelElement,
    BaseInput,
    TelInput,
    ValidationObserver,
    ValidationProvider,
    DatepickerPopup,
    TimePopup,
    AgreementPopup,
    VisitCountPopup,
  },
  head: {
    meta: [
      { name: 'viewport', content: 'width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=2.0' },
    ],
  },
  data() {
    return {
      code_count: '',
      datepickerParams: {
        open: false,
      },
      timeParams: {
        open: false,
      },
      visitCountParams: {
        open: false,
      },
      agreementParams: {
        open: false,
      },
    };
  },
  methods: {
    reserve() {
      this.validateForm(this.$refs.form).then(() => {
        const startDateTime = `${this.visitDate}+${this.visit_time}:00`;
        const endDateTime = `${this.visitDate}+${this.visit_time}:59`;
        this.externalApiClient({
          method: 'get',
          url: CenterGangnamAPIs.VISIT_RESERVATION_SEARCH,
          params: {
            startDateTime,
            endDateTime,
          },
        }).then(({ data }) => {
          if (data.length === 0 || data[0].visitCount < 2) {
            this.externalApiClient({
              url: CenterGangnamAPIs.VISIT_RESERVATION,
              data: {
                visitDateTime: `${this.visitDate} ${this.visit_time}:00`,
                visitorName: this.visitor_name,
                visitorCompanyName: this.visitor_company,
                visitorCount: this.code_count,
                visitorPhone: this.visitor_phone.replace(/-/gi, ''),
                visitorEmail: this.visitor_email,
              },
            }).then(() => {
              this.resultType = 2;
            });
          } else {
            this.resultType = 3;
          }
        });
      });
    },
    goToReservation() {
      window.parent.location.href = `${process.env.VUE_APP_PORTAL}/intro`;
    },
    datePopupOpen() {
      this.datepickerParams.open = true;
    },
    timePopupOpen() {
      this.timeParams.open = true;
    },
    visitCountPopupOpen() {
      this.visitCountParams.open = true;
    },
    AgreementPopupOpen() {
      if (this.agreed === true) return;
      this.agreementParams.open = true;
    },
    setVisitTime(time) {
      this.visit_time = time;
    },
    setVisitCount(count, code) {
      this.code_visitor_count = count;
      this.code_count = code;
    },
    setAgreed(state) {
      this.agreed = state;
    },
  },
};
</script>
