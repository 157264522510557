<template>
  <LayerPopup :popOpen="params.open" @popClose="onClose" title="일자선택" class="datepickerPop">
    <Datepicker
      :inline="true"
      :disabledDates="disabledDates"
      :minimumView="'day'"
      :maximumView="'day'"
      @selected="setVisitTimeValue"
    ></Datepicker>
  </LayerPopup>
</template>

<script>
import Datepicker from '@sum.cumo/vue-datepicker';
import LayerPopup from '@/components/base/LayerPopup.vue';
import '@sum.cumo/vue-datepicker/dist/vuejs-datepicker.css';

export default {
  id: 'datepickerPopup',
  components: {
    Datepicker,
    LayerPopup,
  },
  props: {
    params: {
      type: Object,
      default: () => ({
        open: false,
      }),
    },
    disabledDates: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    onClose(state) {
      this.params.open = state;
    },
    setVisitTimeValue(date) {
      this.$emit('setVisitTimeValue', date);
      this.onClose(false);
    },
  },
};
</script>
